import { Fragment } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/none';

import { SignUpPlanIds } from 'components/forms/constants';
import SignupForm from 'components/forms/signup-full';
import Yoast from 'components/globals/yoast';

import './styles/signup.scss';

export default function Signup2025() {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "signup" } }) {
        edges {
          node {
            wordpress_id
            acf {
              sign_up {
                legal
                features {
                  text
                  image {
                    source_url
                  }
                }
                features_title
              }
            }
            content
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
          }
        }
      }
    }
  `);
  
  const pageContext = data.allWordpressPage.edges[0].node.acf;
  const yoast = data.allWordpressPage.edges[0].node.yoast_head_json;
  const content = data.allWordpressPage.edges[0].node.content;
  const featuresTitle = pageContext.sign_up.features_title;

  return (
    <Layout>
      <Yoast { ...yoast } />
      <section className="signup-page-container light-grey" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <div className="container container-xlarge light-grey">
          <div className="signup-grid">
            
            <div className="signup-column" data-section="signupSection">
              <img
                className="logo is-hidden-tablet"
                src="https://marketing-assets.wheniwork-production.com/2019/08/15163825/logo.svg"
              />
              <Fragment><h2 className="title">Sign Up and Schedule today!</h2>
                <SignupForm
                  uniqueId="signupPage"
                  legalText={ pageContext.sign_up.legal }
                  ctaVariation="Single page"
                  refPlanId={ SignUpPlanIds.PREMIUM_TRIAL_2025 }
                />
              </Fragment>
            </div>
        
            <div className="marketing-column">
              <div className="marketing-column-top">
                <img
                  className="logo is-hidden-mobile"
                  src="https://marketing-assets.wheniwork-production.com/2019/08/15163825/logo.svg"
                />

                <div
                  className="marketing-copy"
                  dangerouslySetInnerHTML={ { __html: content } }
                />
              </div>
              <div className="marketing-column-bottom">
                <h3 className="title">{featuresTitle}</h3>
                <ul className="features">
                  {data.allWordpressPage.edges[0].node.acf.sign_up.features.map(
                    (feature, index) => {
                      return (
                        <li key={ index } className="feature">
                          <div className="feature-content">
                            <img
                              className="feature-icon"
                              src={ feature.image.source_url }
                            />
                            <p>{feature.text}</p>
                          </div>
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
